import { createStore } from "vuex";
import { corePlugins, featurePlugins } from "./plugins";
import globals from "./modules/globals";
import features from "./modules/features";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  strict: debug,
  plugins: [...corePlugins(), ...featurePlugins()],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ...globals,
    ...features
  }
});

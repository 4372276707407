import login from "./login";
import registration from "./registration";

const state = {
  user: {
    data: {
      id: null,
      email: null,
    },
    loading: false,
  },
  authState: {
    access_token: null,
    token_type: "bearer",
    expires_in: 0,
  },
  isLoggedIn: false,
};

const getters = {
  userData(state) {
    return state.user.data;
  },
  authState(state) {
    return state.authState;
  },
  isLoading(state) {
    return state.user.loading;
  },
  isLoggedIn(state) {
    return state.isLoggedIn;
  },
};
const mutations = {
  setUserData(state, data) {
    state.user.data = data;
  },
  setAuthState(state, data) {
    state.authState = data;
  },
  setLoading(state, data) {
    state.user.loading = data;
  },
  setLoggedIn(state, data) {
    state.isLoggedIn = data;
  },
};
const actions = {
  setLoginSuccess({ commit, dispatch }, data) {
    let userBody = {
      id: data.id,
      email: data.email,
      role: data.role,
      name: data.name,
      logo: data.logo,
      isSocialLogin: data.isSocialLogin,
      socialType: data.socialType,
    };
    debugger;
    commit("setUserData", userBody);
    commit("setLoggedIn", data.isLoggedIn);
    localStorage.setItem("userData", JSON.stringify(userBody));
    localStorage.setItem("isLoggedIn", JSON.stringify(data.isLoggedIn));
    let payload = {
      access_token: data.access_token,
      token_type: "bearer",
      expires_in: 0,
    };
    console.log(payload);
    localStorage.setItem("access", JSON.stringify(payload));
    commit("setAuthState", payload);
    dispatch("setAxiosDefaults");
  },
  setAxiosDefaults({ state }) {
    this.$http.defaults.headers.authorization = `${state.authState.token_type} ${state.authState.access_token}`;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    login,
    registration,
  },
};

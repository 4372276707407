import axios from "axios";

const axiosConfig = {
  install: (app) => {
    app.config.globalProperties.$http = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
    });
  },
};

export default axiosConfig;

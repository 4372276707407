const state = {
  input: {
    marketingData: [],
    marketingMessage: "",
    loading: false,
    msgModal: false,
    exportModal: false,
    activeTab: "last_7days",
    firstTime: true,
    limit: 10,
    count: 0,
    validators: {
      marketingMessage: false
    },
    option: ""
  }
};

const getters = {
  inputData(state) {
    return state.input.marketingData;
  },
  marketingMessage(state) {
    return state.input.marketingMessage;
  },
  isLoading(state) {
    return state.input.loading;
  },
  msgModal(state) {
    return state.input.msgModal;
  },
  exportModal(state) {
    return state.input.exportModal;
  },
  activeTab(state) {
    return state.input.activeTab;
  },
  firstTime(state) {
    return state.input.firstTime;
  },
  limit(state) {
    return state.input.limit;
  },
  count(state) {
    return state.input.count;
  },
  option(state) {
    return state.input.option;
  },
  validators(state) {
    return state.input.validators;
  }
};
const mutations = {
  setInputData(state, data) {
    state.input.marketingData = data;
  },
  setMarketingMessage(state, data) {
    state.input.marketingMessage = data;
  },
  setLoading(state, data) {
    state.input.loading = data;
  },
  setMsgModal(state, data) {
    state.input.msgModal = data;
  },
  setExportModal(state, data) {
    state.input.exportModal = data;
  },
  setActiveTab(state, data) {
    state.input.activeTab = data;
  },
  setFirstTime(state, data) {
    state.input.firstTime = data;
  },
  setLimit(state, data) {
    state.input.limit = state.input.limit + data;
  },
  setCount(state, data) {
    state.input.count = data;
  },
  setOption(state, data) {
    state.input.option = data;
  },
  setValidatorsMarketingMessage(state, data) {
    state.input.validators.marketingMessage = data;
  }
};
const actions = {
  fetchMarketingInitialData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("vendor/messages/" + user.id)
        .then(response => {
          commit("setInputData", response.data);
          commit("setLoading", false);
          resolve();
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  fetchCountCustomers({ commit }, tab) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      this.$http
        .get(`message/get/customer?customer_of=${tab.tab}&marketing=${tab.option}`)
        .then(response => {
          console.log(response);
          commit("setLoading", false);
          commit("setCount", response.data.data);
          resolve();
        })
        .catch(error => {
          commit("setLoading", false);
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";

const defaultLayout = "default";

export default defineComponent({
  setup() {
    const { currentRoute } = useRouter();

    const layout = computed(
      () => `${currentRoute.value.meta.layout}-layout`
    );

    return {
      layout
    };
  }
});
</script>

import Pusher from "pusher-js";

const globalPusher = () => {
  var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER
  });
  return pusher;
};
const privatePusher = () => {
  var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authTransport: "jsonp",
    authEndpoint: process.env.VUE_APP_BASE_URL + "broadcasting/auth"
  });
  return pusher;
};

const subscribeTestVendor = () => {
  var channel = globalPusher.subscribe("test-vendor");
  return channel;
};

const subscribePlugin = {
  install: app => {
    app.config.globalProperties.$subscribeTestVendor = subscribeTestVendor;
    app.config.globalProperties.$globalPusher = globalPusher;
    app.config.globalProperties.$privatePusher = privatePusher;
  }
};

export { subscribeTestVendor, globalPusher, privatePusher };
export default subscribePlugin;

import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

function requireAuth(to, from, next) {
  if (store.getters["auth/isLoggedIn"]) {
    checkUser();
  } else {
    if (JSON.parse(localStorage.getItem("isLoggedIn")) == true) {
      checkUser();
    } else {
      next({ name: "AdminSignIn" });
    }
  }

  function checkUser() {
    let user = store.getters["auth/userData"];
    if (user.id != null) {
      if (to.meta.requiresAdmin) {
        if (user.role === "administrator") {
          next();
        } else {
          next({ name: "AdminSignIn" });
        }
      } else {
        next();
      }
    } else {
      let localUser = JSON.parse(localStorage.getItem("userData"));
      if (localUser !== null) {
        let auth = store.getters["auth/setAuthState"];
        if (auth) {
          if (to.meta.requiresAdmin) {
            if (localUser.role === "admin") {
              next();
            } else {
              next({ name: "AdminSignIn" });
            }
          } else {
            next();
          }
        } else {
          let localAuth = JSON.parse(localStorage.getItem("access"));
          if (localAuth) {
            store.commit("auth/setAuthState", localAuth);
            store.dispatch("auth/setAxiosDefaults");
            next();
          } else {
            next({ name: "AdminSignIn" });
          }
        }
      } else {
        next({ name: "AdminSignIn" });
      }
    }
  }
}

const routes = [
  {
    path: "/verify-email",
    name: "verifyEmail",
    component: () =>
      import(
        /* webpackChunkName: "queue-ticket" */ "@/views/EmailVerification"
      ),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/admin/signin",
    name: "AdminSignIn",
    component: () =>
      import(
        /* webpackChunkName: "queue-ticket" */ "@/views/Admin/Session/SigninForm"
      ),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () =>
      import(/* webpackChunkName: "queue-ticket" */ "@/views/ResetPassword"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(/* webpackChunkName: "queue-ticket" */ "@/views/ForgotPassword"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/verify-otp",
    name: "OTPVerification",
    component: () =>
      import(/* webpackChunkName: "queue-ticket" */ "@/views/OtpVerification"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/user",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "queue-ticket" */ "@/views/User/Signin"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/user/signin",
    name: "UserSignIn",
    component: () =>
      import(
        /* webpackChunkName: "queue-ticket" */ "@/views/User/Session/SigninForm"
      ),
    meta: {
      layout: "empty",
    },
  },

  {
    path: "/user/signup",
    name: "UserSignUp",
    component: () =>
      import(
        /* webpackChunkName: "queue-ticket" */ "@/views/User/Session/SignupForm"
      ),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/user/home",
    name: "UserHome",
    component: () =>
      import(/* webpackChunkName: "queue-ticket" */ "@/views/User/Home"),
    meta: {
      layout: "empty",
    },
    beforeEnter: requireAuth,
  },

  {
    path: "/vendor",
    name: "Vendor",
    component: () =>
      import(/* webpackChunkName: "vendor-index" */ "@/views/Vendor/Index"),
    children: [
      {
        path: "profile",
        name: "VendorProfile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Vendor/Profile"),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "queue-console",
        name: "VendorProfileQ",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/views/Vendor/Queue/Queues"
          ),
        beforeEnter: requireAuth,
        meta: {
          layout: "empty",
        },
      },
      {
        path: "queue",
        name: "VendorQueue",
        component: () =>
          import(/* webpackChunkName: "queue" */ "@/views/Vendor/Queue"),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "changepassword",
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "password" */ "@/views/Vendor/ChangePassword"
          ),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "notifications",
        name: "VendorNotifications",
        component: () =>
          import(
            /* webpackChunkName: "notifications" */ "@/views/Vendor/Notifications"
          ),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "statistics",
        name: "VendorStatistics",
        component: () =>
          import(
            /* webpackChunkName: "statistics" */ "@/views/Vendor/Statistics"
          ),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "marketing",
        name: "VendorMarketing",
        component: () =>
          import(
            /* webpackChunkName: "marketing" */ "@/views/Vendor/Marketing"
          ),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "sms-top-up",
        name: "VendorSmstopup",
        component: () =>
          import(/* webpackChunkName: "smstopup" */ "@/views/Vendor/Smstopup"),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "billing",
        name: "VendorBilling",
        component: () =>
          import(/* webpackChunkName: "billing" */ "@/views/Vendor/Billing"),
        beforeEnter: requireAuth,
        meta: {
          layout: "default",
        },
      },
      {
        path: "signin",
        name: "VendorSignin",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/Vendor/SignIn"),
        meta: {
          layout: "empty",
        },
      },
      {
        path: "forget-password",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/Vendor/ForgotPassword"
          ),
        meta: {
          layout: "empty",
        },
      },
      {
        path: "signup",
        name: "VendorSignup",
        component: () =>
          import(/* webpackChunkName: "signup" */ "@/views/Vendor/SignUp"),
        meta: {
          layout: "empty",
        },
      },
      {
        path: ":pathMatch(.*)",
        redirect: "/vendor/billing",
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/User/UserStart"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/joining-load",
    name: "JoiningLoad",
    component: () =>
      import(/* webpackChunkName: "joining-load" */ "@/views/User/JoiningLoad"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/queue-ticket",
    name: "UserQueueTicket",
    component: () =>
      import(/* webpackChunkName: "queue-ticket" */ "@/views/User/QueueTicket"),
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/Admin/DashboardHome"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "admin",
    },
  },
  {
    path: "/admin/stats",
    name: "Stats",
    component: () => import("@/views/Admin/Stats.vue"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/vendors",
    name: "AdminVendors",
    component: () => import("@/views/Admin/Vendors"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/customers",
    name: "AdminCustomers",
    component: () => import("@/views/Admin/Customers"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/customer",
    name: "AdminCustomer",
    component: () => import("@/views/Admin/Customer"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/vendor",
    name: "AdminVendor",
    component: () => import("@/views/Admin/Vendor"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/message",
    name: "AdminMessage",
    component: () => import("@/views/Admin/Message"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/logs",
    name: "AdminLogs",
    component: () => import("@/views/Admin/Logs"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: "/admin/vendor/invoices",
    name: "AdminVendorInvoices",
    component: () => import("@/views/Admin/VendorInvoices"),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: "empty",
    },
  },
  {
    path: '/admin/vendor/invoice-details',
    name: 'AdminVendorInvoiceDetails',
    component: () => import('@/views/Admin/VendorInvoiceDetails'),
    beforeEnter: requireAuth,
    meta: {
      requiresAdmin: true,
      layout: 'empty',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

const state = {
  input: {
    profileData: {
      name: "",
      tradingAs: "",
      ABN: null,
      address: "",
      suburb: "",
      postcode: "",
      state: "",
      country: "",
      contactName: "",
      phoneNumber: "",
      email: "",
      logo: ""
    },
    loading: false,
    uploadModal: false,
    selectedFile: null,
    editable: false,
    logo: '',
    vendorName: ''
  }
};

const getters = {
  inputData(state) {
    return state.input.profileData;
  },
  isLoading(state) {
    return state.input.loading;
  },
  uploadModal(state) {
    return state.input.uploadModal;
  },
  selectedFile(state) {
    return state.input.selectedFile;
  },
  editable(state) {
    return state.input.editable;
  },
  vendorLogo(state) {
    return state.input.logo
  },
  VendorName(state) {
    return state.input.vendorName
  }
};
const mutations = {
  setVendorLogo(state, data) {
    state.input.logo = data;
  },
  setvendorName(state, data) {
    state.input.vendorName = data;
  },
  setInputData(state, data) {
    state.input.profileData.name = data[0].business_name;
    state.input.profileData.tradingAs = data[0].trading_as;
    state.input.profileData.ABN = data[0].abn;
    state.input.profileData.address = data[0].address;
    state.input.profileData.suburb = data[0].suburb;
    state.input.profileData.postcode = data[0].postcode;
    state.input.profileData.state = data[0].state;
    state.input.profileData.country = data[0].country;
    state.input.profileData.contactName = data[0].contact_name;
    state.input.profileData.phoneNumber = data[0].phone_number;
    state.input.profileData.email = data[0].email;
    state.input.profileData.logo = data[0].logo;
  },
  setSingleInputData(state, data) {
    state.input.profileData[data.name] = data.value;
  },
  setLoading(state, data) {
    state.input.loading = data;
  },
  setUploadModal(state, data) {
    state.input.uploadModal = data;
  },
  setSelectedFile(state, data) {
    state.input.selectedFile = data;
  },
  setEditable(state, data) {
    state.input.editable = data;
  }
};
const actions = {
  fetchProfileInitialData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("vendor/profile/" + user.id)
        .then(response => {
          commit("setInputData", response.data.data);
          localStorage.setItem(
            "logo",
            JSON.stringify(
              process.env.VUE_APP_BASE_URL + response.data.data[0].logo
            )
          );
          commit("setLoading", false);
          resolve();
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  updateProfileData({ state, dispatch, commit }) {
    commit("setLoading", true);
    let businessBody = {
      business_name: state.input.profileData.name,
      trading_as: state.input.profileData.tradingAs,
      abn: state.input.profileData.ABN
    };
    let addressBody = {
      address: state.input.profileData.address,
      suburb: state.input.profileData.suburb,
      postcode: state.input.profileData.postcode,
      state: state.input.profileData.state,
      country: state.input.profileData.country
    };
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("userData"));
      this.$http
        .put("vendor/profile/" + user.id, businessBody)
        .then(response => {
          console.log(response.data);
          this.$http
            .put("vendor/address/" + user.id, addressBody)
            .then(response => {
              console.log(response.data);
              dispatch("fetchProfileInitialData");
              resolve();
            })
            .catch(error => {
              let errorMessage = error.message;
              console.error("There was an error!", error);
              commit("setLoading", false);
              reject(errorMessage);
            });
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

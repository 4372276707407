const state = {
  form: {
    data: {
      accountData: {
        email: "",
        password: "",
        repassword: "",
        name: "",
        phone_number: null
      },
      businessData: {
        business_name: "",
        abn: null
      },
      cardData: {
        name: "",
        country: "",
        state: "",
        city: "",
        zip: ""
      }
    },
    errors: [],
    loading: false,
    validators: {
      accountData: {
        email: {
          isValid: true,
          errorMsg: ""
        },
        password: {
          isValid: true,
          errorMsg: ""
        },
        repassword: {
          isValid: true,
          errorMsg: ""
        }
      },
      businessData: {
        name: {
          isValid: true,
          errorMsg: ""
        },
        contactName: {
          isValid: true,
          errorMsg: ""
        },
        ABN: {
          isValid: true,
          errorMsg: ""
        },
        phoneNumber: {
          isValid: true,
          errorMsg: ""
        }
      },
      cardData: {
        name: {
          isValid: true,
          errorMsg: ""
        },
        country: {
          isValid: true,
          errorMsg: ""
        },
        state: {
          isValid: true,
          errorMsg: ""
        },
        city: {
          isValid: true,
          errorMsg: ""
        },
        zip: {
          isValid: true,
          errorMsg: ""
        }
      }
    }
  }
};

const getters = {
  formData(state) {
    return state.form;
  },
  formAccountData(state) {
    console.log("formAccountData ", state);
    return state.form.data.accountData;
  },
  formBusinessData(state) {
    return state.form.data.businessData;
  },
  formCardData(state) {
    return state.form.data.cardData;
  },
  validators(state) {
    return state.form.validators;
  }
};
const mutations = {
  setFormData(state, data) {
    var str = data.input.split(".");
    state.form.data[str[0]][str[1]] = data.value;
    console.log(state.form.data);
  },
  setFormAccountData(state, data) {
    state.form.data.accountData = data;
  },
  setFormBusinessData(state, data) {
    state.form.data.businessData = data;
  },
  setFormCardData(state, data) {
    state.form.data.cardData = data;
  },
  setEmailValidator(state, data) {
    console.log("state email", state, "data", data);
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    console.log("is not empty ", isNotEmpty);

    //Check if email is has the correct format of a standard email
    var isValidEmail = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data)
      ? false
      : true;
    var isEmailAccepted = false;
    var errorMessage = "";

    //If validations did not pass assingn specific error to display
    if (isNotEmpty && isValidEmail) isEmailAccepted = true;
    if (!isEmailAccepted)
      if (!isNotEmpty) errorMessage = "Email is required";
      else errorMessage = "Invalid email";
    if (!isNotEmpty && isValidEmail) {
      isEmailAccepted = true;
      errorMessage = "";
    }
    state.form.validators.accountData.email.isValid = isValidEmail;
    state.form.validators.accountData.email.errorMsg = errorMessage;
  },
  setPasswordValidator(state, data) {
    //Check if password data is not empty
    var isNotEmpty = data ? true : false;
    state.form.validators.accountData.password.isValid = isNotEmpty;
    state.form.validators.accountData.password.errorMsg =
      "Password is required";
    var confirmPasswordIsNotEmpty = state.form.data.accountData.repassword
      ? true
      : false;
    if (confirmPasswordIsNotEmpty && isNotEmpty) {
      var confirmPasswordErrorMsg = "";
      var isConfirmPasswordValid = false;
      if (
        state.form.data.accountData.password ===
          state.form.data.accountData.repassword &&
        state.form.validators.accountData.password.isValid
      )
        isConfirmPasswordValid = true;
      else confirmPasswordErrorMsg = "Password does not match";
      state.form.validators.accountData.repassword.isValid = isConfirmPasswordValid;
      state.form.validators.accountData.repassword.errorMsg = confirmPasswordErrorMsg;
    }
  },
  setConfirmPasswordValidator(state, data) {
    //Check if repassword data is not empty
    var isNotEmpty = data ? true : false;
    var isPasswordValid = false;
    var errorMessage = "";
    if (isNotEmpty)
      if (
        state.form.data.accountData.password ===
          state.form.data.accountData.repassword &&
        state.form.validators.accountData.password.isValid
      )
        isPasswordValid = true;
      else errorMessage = "Password does not match";
    else if (
      !isNotEmpty &&
      state.form.validators.accountData.password.isValid &&
      state.form.validators.accountData.password
    )
      errorMessage = "Re-enter password";
    state.form.validators.accountData.repassword.isValid = isPasswordValid;
    state.form.validators.accountData.repassword.errorMsg = errorMessage;
  },
  setBusinessDataNameValidator(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    console.log("isnotEmpty ", isNotEmpty);
    state.form.validators.businessData.name.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.businessData.name.errorMsg = "This field is required";
  },
  setBusinessDataContactNameValidator(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.businessData.contactName.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.businessData.contactName.errorMsg =
      "This field is required";
  },
  setBusinessDataABNValidator(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.businessData.ABN.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.businessData.ABN.errorMsg = "This field is required";
  },
  setBusinessDataPhoneNumberValidator(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.businessData.phoneNumber.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.businessData.phoneNumber.errorMsg =
      "This field is required";
  },
  setCardDataName(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.cardData.name.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.cardData.name.errorMsg = "This field is required";
  },
  setCardDataCountry(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.cardData.country.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.cardData.country.errorMsg = "This field is required";
  },
  setCardDataState(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.cardData.state.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.cardData.state.errorMsg = "This field is required";
  },
  setCardDataCity(state, data) {
    //Can be changed for custom error message for each field
    var isNotEmpty = data ? true : false;
    state.form.validators.cardData.city.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.cardData.city.errorMsg = "This field is required";
  },
  setCardDataZip(state, data) {
    var isNotEmpty = data ? true : false;
    state.form.validators.cardData.zip.isValid = isNotEmpty;
    //Can be changed for custom error message for each field
    state.form.validators.cardData.zip.errorMsg = "This field is required";
  }
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
